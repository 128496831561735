var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "point-use-list" }, [
        _c("div", { staticClass: "point-use-guide" }, [
          _vm._m(0),
          _c("div", { staticClass: "available-point" }, [
            _c("dl", [
              _c("dt", [_vm._v("사용 가능한 포인트")]),
              _vm.isLogin
                ? _c("dd", [
                    _c("p", { staticClass: "num-txt" }, [
                      _c("i"),
                      _vm._v(
                        _vm._s(_vm._f("$numberFormatter")(_vm.userPoint || 0))
                      ),
                      _c("span", [_vm._v("P")]),
                    ]),
                  ])
                : _vm._e(),
              !_vm.isLogin
                ? _c("dd", [
                    _c("p", { staticClass: "txt" }, [
                      _vm._v(" 로그인 후 확인하세요. "),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "point-use-items" },
          _vm._l(_vm.products, function (item) {
            return _c(
              "div",
              { key: item.goodsCode, staticClass: "point-use-item" },
              [
                _c(
                  "div",
                  {
                    staticClass: "voucher-img",
                    staticStyle: { width: "100%", height: "100%" },
                  },
                  [_c("img", { attrs: { src: item.goodsImgUrl } })]
                ),
                _c("p", { staticClass: "voucher-txt" }, [
                  _vm._v(
                    " [" +
                      _vm._s(item.brandName) +
                      "] " +
                      _vm._s(item.goodsName) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "voucher-info" }, [
                  _c("p", { staticClass: "num-txt" }, [
                    _vm._v(
                      " " + _vm._s(_vm._f("$numberFormatter")(item.pointAmount))
                    ),
                    _c("span", [_vm._v("P")]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn-change btn-change-none",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.exchange(item)
                        },
                      },
                    },
                    [_vm._v(" 교환하기 ")]
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showConfirm,
              expression: "showConfirm",
            },
          ],
          staticClass: "confirm-password cp1",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn-close",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.confirmClose($event)
                },
              },
            },
            [_vm._v(" 닫기 ")]
          ),
          _c("p", { staticClass: "title" }, [_vm._v(" 기프티콘 교환 ")]),
          _c("p", { staticClass: "tip" }, [
            _vm._v(" ※ 사용하신 포인트는 다시 되돌릴 수 없습니다. "),
          ]),
          _c("div", { staticClass: "point-info" }, [
            _c("dl", [
              _c("dt", [_vm._v("보유 포인트")]),
              _c("dd", [
                _vm._v(_vm._s(_vm._f("$numberFormatter")(_vm.userPoint || 0))),
                _c("span", [_vm._v("P")]),
              ]),
            ]),
            _c("dl", [
              _c("dt", [_vm._v("사용 예정 포인트")]),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("$numberFormatter")(
                      _vm.choiceItem ? _vm.choiceItem.pointAmount : 0
                    )
                  )
                ),
                _c("span", [_vm._v("P")]),
              ]),
            ]),
            _c("dl", { staticStyle: { "border-top": "0px" } }, [
              _c("dt", [_vm._v("교환시 잔여 포인트")]),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("$numberFormatter")(
                      (_vm.userPoint || 0) -
                        (_vm.choiceItem ? _vm.choiceItem.pointAmount : 0)
                    )
                  )
                ),
                _c("span", [_vm._v("P")]),
              ]),
            ]),
          ]),
          _c("label", { staticClass: "password-label" }, [
            _vm._v("비밀번호를 입력하세요."),
          ]),
          _c("div", { staticClass: "text-wrap" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
                { name: "number", rawName: "v-number" },
                { name: "focus", rawName: "v-focus" },
              ],
              attrs: { type: "password", maxlength: "6" },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inValidMessage,
                  expression: "inValidMessage",
                },
              ],
              staticClass: "message-box",
            },
            [
              _c("p", { staticClass: "error-message" }, [
                _vm._v(" " + _vm._s(_vm.inValidMessage) + " "),
              ]),
            ]
          ),
          _c("div", { staticClass: "flex-c" }, [
            _c(
              "button",
              {
                staticClass: "btn-change-layer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.fetchOrder($event)
                  },
                },
              },
              [_vm._v(" 교환하기 ")]
            ),
          ]),
        ]
      ),
      _c("popup-verification", {
        ref: "popupVerification",
        on: { result: _vm.verifyResult },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("교환하신 기프티콘은 모바일로 즉시 발송됩니다.")]),
      _c("li", [_vm._v("기프티콘은 운영 정책 상 변경될 수 있습니다.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }