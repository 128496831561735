<template>
  <article class="contents">
    <div class="point-wrap">
      <div class="tab-nav common-type">
        <ul>
          <li :class="{ 'on': currTab === 'point-use' }">
            <a @click.prevent="changeTab('point-use')">포인트 사용하기</a>
          </li>
          <li :class="{ 'on': currTab === 'point-history' }">
            <a @click.prevent="changeTab('point-history')">포인트 적립 내역</a>
          </li>
          <li :class="{ 'on': currTab === 'point-guide-rules' }">
            <a @click.prevent="changeTab('point-guide-rules')">포인트 제도 안내</a>
          </li>
        </ul>
      </div>

      <div class="tab-contents">
        <component :is="currTab" />
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import PointHistory from './PointHistory.vue'
import PointUse from './PointUse.vue'
import PointGuideRules from './PointGuideRules.vue'

export default {
  components: {
    PointUse,
    PointHistory,
    PointGuideRules,
  },
  data() {
    return {
      currTab: 'point-use',
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    userName(val) {
      if (val === null) {
        this.$router.push({ name: 'app-main' })
      }
    },
  },
  mounted() {
  },
  methods: {
    changeTab(tab) {
      this.currTab = tab
    },
  },
}
</script>
