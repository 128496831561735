<template>
  <div>
    <!-- 포인트 적립 내역 -->
    <div class="my-point-guide">
      <div
        class="use-point"
        @click.stop="changePlusMinus(1)"
      >
        <dl>
          <dt>사용 가능한 포인트</dt>
          <dd>
            <p class="num-txt">
              {{ (userPoint || 0) | $numberFormatter }}<span>P</span>
            </p>
          </dd>
        </dl>
      </div>
      <div
        class="use-point"
        @click.stop="changePlusMinus(3)"
      >
        <dl>
          <dt>30일 내 소멸 예정 포인트</dt>
          <dd>
            <!-- <p class="d-day">
              <span>D-{{ expiringDays }}</span>
            </p> -->
            <p class="num-txt">
              {{ expiringPoint | $numberFormatter }}<span>P</span>
            </p>
          </dd>
        </dl>
      </div>
      <div class="month-mission">
        <span class="title">이달의 미션</span>
        <ol>
          <!-- <li><span>1.</span>약품 정보&nbsp;<strong>월 {{ drugReply.goal }}회 이상</strong> 댓글 작성 시 {{ drugReply.point | $numberFormatter }} P</li> -->
          <li v-if="webinarView !== null">
            <span>1.</span>제약 웹 세미나&nbsp;<strong>월 {{ webinarView.goal }}회 이상</strong> 일정 등록 시 {{ webinarView.point | $numberFormatter }} P
          </li>
          <li v-else>
            <span>1.</span>제약 웹 세미나&nbsp;<strong>등록 된 미션정보가 없습니다.</strong>
          </li>
          <li v-if="mediReply !== null">
            <span>2.</span>메디컬 트렌드&nbsp;<strong>월 {{ mediReply.goal }}회 이상</strong> 댓글 작성 시 {{ mediReply.point | $numberFormatter }} P
          </li>
          <li v-else>
            <span>2.</span>메디컬 트렌드&nbsp;<strong>등록 된 미션정보가 없습니다.</strong>
          </li>
          <li v-if="wChoice !== null">
            <span>3.</span>Weekly Choice&nbsp;<strong>월 {{ wChoice.goal }}회 이상</strong> 참여 시 {{ wChoice.point | $numberFormatter }} P
          </li>
          <li v-else>
            <span>3.</span>Weekly Choice&nbsp;<strong>등록 된 미션정보가 없습니다.</strong>
          </li>
        </ol>

        <div class="month-range-bar">
          <div class="participation-status">
            <!-- <div
              class="ps-item"
              :class="{ completion: drugReply.complete }"
              title="약품 정보"
            >
              <p class="title">
                약품 정보
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: drugReply.rate + '%' }"
                  />
                </div>
                <span
                  v-if="drugReply.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ drugReply.my }} <em>/</em> {{ drugReply.goal }}</span>
              </div>
            </div> -->
            <div
              v-if="webinarView !== null"
              class="ps-item"
              :class="{ completion: webinarView.complete }"
              title="제약 웹 세미나"
            >
              <p class="title">
                제약 웹 세미나
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: webinarView.rate + '%' }"
                  />
                </div>
                <span
                  v-if="webinarView.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ webinarView.my }} / {{ webinarView.goal }}</span>
              </div>
            </div>
            <div
              v-if="mediReply !== null"
              class="ps-item"
              :class="{ completion: mediReply.complete }"
              title="메디컬 트렌드"
            >
              <p class="title">
                메디컬 트렌드
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: mediReply.rate + '%' }"
                  />
                </div>
                <span
                  v-if="mediReply.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ mediReply.my }} <em>/</em> {{ mediReply.goal }}</span>
              </div>
            </div>
            <div
              v-if="wChoice !== null"
              class="ps-item"
              :class="{ completion: wChoice.complete }"
              title="Weekly Choice"
            >
              <p class="title">
                Weekly Choice
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: wChoice.rate + '%' }"
                  />
                </div>
                <span
                  v-if="wChoice.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ wChoice.my }} / {{ wChoice.goal }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="mission-tip">
      ※ 이달의 미션은 매월 변경됩니다.
    </p>

    <div class="date-wrap">
      <div class="radio-date">
        <input
          id="monthCheck1"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="1"
        >
        <label
          for="monthCheck1"
          style="cursor: pointer;"
        >1개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck2"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="3"
        >
        <label
          for="monthCheck2"
          style="cursor: pointer;"
        >3개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck3"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="6"
        >
        <label
          for="monthCheck3"
          style="cursor: pointer;"
        >6개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck4"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="12"
        >
        <label
          for="monthCheck4"
          style="cursor: pointer;"
        >1년</label>
      </div>

      <div
        class="period-calendar-wrap"
        style="display: flex; position: relative"
      >
        <div class="text-wrap calendar-type">
          <date-picker
            v-model="filter.startDate"
            :lang="datePicker.lang"
            value-type="format"
          />
        </div>
        <label class="bu-wave" />
        <div class="text-wrap calendar-type">
          <date-picker
            v-model="filter.endDate"
            :lang="datePicker.lang"
            value-type="format"
          />
        </div>
      </div>
    <!-- <button class="btn-period">
        검색
      </button> -->
    </div>
    <div class="tab-nav-in">
      <ul>
        <li :class="{ 'on': filter.plusMinus === 0 }">
          <a @click.prevent="changePlusMinus(0)">전체</a>
        </li>
        <li :class="{ 'on': filter.plusMinus === 1 }">
          <a @click.prevent="changePlusMinus(1)">적립</a>
        </li>
        <li :class="{ 'on': filter.plusMinus === 2 }">
          <a @click.prevent="changePlusMinus(2)">사용(소멸)</a>
        </li>
        <li :class="{ 'on': filter.plusMinus === 3 }">
          <a @click.prevent="changePlusMinus(3)">소멸 예정</a>
        </li>
      </ul>
    </div>
    <div class="tab-contents-in">
      <div v-if="filter.plusMinus !== 3">
        <div
          class="tb-list-type"
        >
          <table>
            <colgroup>
              <col style="width: 150px">
              <col style="width: 250px">
              <col style="width: auto">
              <col style="width: 220px">
            </colgroup>

            <thead>
              <tr>
                <th>상태</th>
                <th>포인트</th>
                <th>상세 내용</th>
                <th>일자</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="m in list"
                :key="m.id"
              >
                <td><span>{{ m.plusMinusName }}</span></td>
                <td>
                  <span
                    :class="{
                      'txt-blue': m.plusMinus === 1,
                      'txt-red': m.plusMinus === 2,
                      'txt-gray': m.plusMinus === 3
                    }"
                  >{{ m.amount | $numberFormatter }} P</span></td>
                <td class="txt-left">
                  <p class="ellipsis-txt">
                    {{ m.description }}
                  </p>
                </td>
                <td>{{ m.pointDate | $dateFormatter }}</td>
              </tr>
            </tbody>
          </table>
          <p
            v-if="list === null || list.length === 0"
            class="txt-empty"
          >
            적립/사용(소멸) 내역이 없습니다.
          </p>
        </div>
      </div>
      <div v-else>
        <p class="gray-tip">
          ※ 소멸 예정 내역은 상단의 기간 검색과 상관없이, 30일 이내에 소멸 예정인 포인트를 표시합니다.
        </p>
        <div
          class="tb-list-type"
        >
          <table>
            <colgroup>
              <col style="width: auto">
              <col style="width: auto">
            </colgroup>

            <thead>
              <tr>
                <th>소멸 예정 포인트</th>
                <th>소멸 예정 일자</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="m in list"
                :key="m.id"
              >
                <td><span>{{ m.amount | $numberFormatter }} P</span></td>
                <td><div class="flex-c">
                  {{ m.pointDate | $dateFormatter }}<em class="point-d-day">({{ m.description }})</em>
                </div></td>
              </tr>
            </tbody>
          </table>
          <p
            v-if="list === null || list.length === 0"
            class="txt-empty"
          >
            소멸 예정 내역이 없습니다.
          </p>
        </div>
      </div>
    </div>

    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { isUserLoggedIn, changeUserPoint } from '@/auth/utils'
import DatePicker from 'vue2-datepicker'
import '@/libs/vue2-datepicker/calendar-vue.css'
import { locale } from '@/libs/vue2-datepicker/lang/ko-kr'

export default {
  components: {
    DatePicker,
  },

  props: {
    subTab: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      list: null,
      expiringPoint: 0,
      myGoals: [],
      monthGoals: null,
      filter: {
        startDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        plusMinus: 0,
      },
      datePicker: {
        lang: locale(),
      },
      drugReply: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      mediReply: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      webinarView: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      wChoice: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      monthCheck: 1
    }
  },
  computed: {
    ...mapGetters({
      userPoint: 'account/getPoint',
    }),
  },
  watch: {
    filter: {
      deep: true,

      handler() {
        this.fetchHistories()
      },
    },
    subTab: {
      immediate: true,

      handler(newValue) {
        this.filter.plusMinus = newValue
      },
    },
    monthCheck() {
      this.filter.startDate = dayjs().add(-this.monthCheck, 'month').format('YYYY-MM-DD')
      this.filter.endDate = dayjs().format('YYYY-MM-DD')
    },
  },
  async mounted() {
    const isLogin = await isUserLoggedIn()

    if (!isLogin) {
      this.$router.push({
        name: 'login-main',
        query: { redirect: 'app-point-mall-main' }
      }).catch(() => {})
      return
    }

    this.fetchMonthMission()
    this.fetchUserPoint()
    this.fetchHistories()
    this.fetchExpiringPoint()
  },
  methods: {
    changePlusMinus(value) {
      this.filter.plusMinus = value
      this.$emit("changeSubTab", value)
    },
    fetchUserPoint() {
      axios.get('/fu/point-history/user-point')
        .then(rs => {
          changeUserPoint(rs.data)
        })
    },
    fetchExpiringPoint() {
      axios.get('/fu/point-history/expiring-point')
        .then(rs => {
          const { availAmount } = rs.data
          this.expiringPoint = availAmount || 0
          // this.expiringDays = expiringDays || 15
        })
    },
    missionSetting(data, category) {
      const mission = data.find(x => x.category === category)
      if (mission == null) return null

      return {
        my: mission.count,
        goal: mission.goal,
        point: mission.point,
        rate: mission.complete ? 100 : (mission.count >= mission.goal ? 100 : Math.round((mission.count / mission.goal) * 100)),
        complete: mission.complete
      }
    },
    fetchMonthMission() {
      axios.get('/fu/point-history/mission')
        .then(rs => {
          // this.drugReply = this.missionSetting(rs.data, 12)
          this.mediReply = this.missionSetting(rs.data, 13)
          this.webinarView = this.missionSetting(rs.data, 14)
          this.wChoice = this.missionSetting(rs.data, 16)
        })
    },
    fetchHistories() {
      axios.post('/fu/point-history/list', {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        plusMinus: this.filter.plusMinus === 0 ? null : this.filter.plusMinus,
      })
        .then(rs => {
          this.list = rs.data
        })
    },
  },
}
</script>
