<template>
  <article class="contents">
    <div class="point-wrap">
      <div class="tab-nav common-type">
        <ul>
          <li :class="{ 'on': currTab === 'point-use' }">
            <a @click.prevent="changeTab('point-use')">포인트 사용하기</a>
          </li>
          <li :class="{ 'on': currTab === 'point-history' }">
            <a @click.prevent="changeTab('point-history')">포인트 적립 내역</a>
          </li>
          <li :class="{ 'on': currTab === 'point-guide-rules' }">
            <a @click.prevent="changeTab('point-guide-rules')">포인트 제도 안내</a>
          </li>
        </ul>
      </div>

      <div class="tab-contents">
        <component
          :is="currTab"
          :sub-tab="subTab"
          @changeSubTab="changeSubTab"
        />
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import PointHistory from './PointHistory.vue'
import PointUse from './PointUse.vue'
import PointGuideRules from './PointGuideRules.vue'

export default {
  components: {
    PointUse,
    PointHistory,
    PointGuideRules,
  },
  data() {
    return {
      currTab: 'point-use',
      subTab: 0,
    }
  },
  computed: {
    ...mapGetters({
      params: 'util/getParams',
      userName: 'account/getName',
    }),
  },
  watch: {
    params() {
      this.routing()
    },
    userName(val) {
      if (val === null) {
        this.$router.push({ name: 'app-main' })
      }
    },
  },
  mounted() {
    this.routing()
  },
  methods: {
    changeTab(tab) {
      this.currTab = tab
    },
    changeSubTab(value) {
      this.subTab = value
    },
    routing() {
      if (this.params && this.params.route === this.$route.name) {
        this.changeTab(this.params.tabType)
        if (this.params.subTab) this.subTab = this.params.subTab

        if (this.params) this.$store.commit('util/setParams', null)
      }
    }
  },
}
</script>
