<template>
  <div>
    <!-- 포인트 사용하기 -->
    <div class="point-use-list">
      <div class="point-use-guide">
        <ul>
          <li>교환하신 기프티콘은 모바일로 즉시 발송됩니다.</li>
          <li>기프티콘은 운영 정책 상 변경될 수 있습니다.</li>
        </ul>

        <div class="available-point">
          <dl>
            <dt>사용 가능한 포인트</dt>
            <dd v-if="isLogin">
              <p class="num-txt">
                <i />{{ (userPoint || 0) | $numberFormatter }}<span>P</span>
              </p>
            </dd>
            <dd v-if="!isLogin">
              <p class="txt">
                로그인 후 확인하세요.
              </p>
            </dd>
          </dl>
        </div>
      </div>

      <div class="point-use-items">
        <div
          v-for="item in products"
          :key="item.goodsCode"
          class="point-use-item"
        >
          <div
            class="voucher-img"
            style="width: 100%; height:100%;"
          >
            <img
              :src="item.goodsImgUrl"
            >
          </div>

          <p class="voucher-txt">
            [{{ item.brandName }}] {{ item.goodsName }}
          </p>

          <div class="voucher-info">
            <p class="num-txt">
              {{ item.pointAmount | $numberFormatter }}<span>P</span>
            </p>
            <button
              class="btn-change btn-change-none"
              @click.prevent="exchange(item)"
            >
              교환하기
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showConfirm"
      class="confirm-password cp1"
    >
      <button
        class="btn-close"
        @click.prevent="confirmClose"
      >
        닫기
      </button>
      <p class="title">
        기프티콘 교환
      </p>
      <p class="tip">
        ※ 사용하신 포인트는 다시 되돌릴 수 없습니다.
      </p>
      <div class="point-info">
        <dl>
          <dt>보유 포인트</dt>
          <dd>{{ (userPoint || 0) | $numberFormatter }}<span>P</span></dd>
        </dl>
        <dl>
          <dt>사용 예정 포인트</dt>
          <dd>{{ (choiceItem ? choiceItem.pointAmount : 0) | $numberFormatter }}<span>P</span></dd>
        </dl>
        <dl style="border-top: 0px">
          <dt>교환시 잔여 포인트</dt>
          <dd>{{ (userPoint || 0) - (choiceItem ? choiceItem.pointAmount : 0) | $numberFormatter }}<span>P</span></dd>
        </dl>
      </div>
      <label class="password-label">비밀번호를 입력하세요.</label>
      <div class="text-wrap">
        <input
          v-model="password"
          v-number
          v-focus
          type="password"
          maxlength="6"
        >
      </div>
      <div
        v-show="inValidMessage"
        class="message-box"
      >
        <p class="error-message">
          {{ inValidMessage }}
        </p>
      </div>

      <div class="flex-c">
        <button
          class="btn-change-layer"
          @click.prevent="fetchOrder"
        >
          교환하기
        </button>
      </div>
    </div>

    <popup-verification
      ref="popupVerification"
      @result="verifyResult"
    />

    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { errorFormatter } from '@/libs/utils/filters'
import { getUserData, isUserLoggedIn, changeUserPoint } from '@/auth/utils'

import PopupVerification from '@/views/apps/popup/PopupVerification.vue'

export default {
  components: {
    PopupVerification
  },
  data() {
    return {
      isLogin: false,
      password: '',
      products: null,
      choiceItem: null,
      showConfirm: false,
      inValidMessage: null,
      isProcessing: false,
    }
  },
  computed: {
    ...mapGetters({
      userPoint: 'account/getPoint',
    }),
  },
  async mounted() {
    this.isLogin = await isUserLoggedIn()

    this.fetchProducts()
  },
  methods: {
    async exchange(item) {
      const isLogin = await isUserLoggedIn()

      if (!isLogin) {
        this.$router.push({
          name: 'login-main',
          query: { redirect: 'app-point-mall-main' }
        }).catch(() => {})
        return
      }

      this.choiceItem = item

      if (!this.userPoint || (this.userPoint - this.choiceItem.pointAmount) < 0) {
        this.showAlertDialog('보유 포인트가 부족합니다.')
      } else if (getUserData().verify === false) {
        this.$refs.popupVerification.open()
      } else {
        this.showConfirm = true
      }
    },

    verifyResult() {
      this.showConfirm = true
    },

    confirmClose() {
      this.password = ''
      this.inValidMessage = ''
      this.showConfirm = false
    },

    fetchOrder() {
      if (!this.isProcessing) {
        this.inValidMessage = ''

        if (!this.password) {
          this.inValidMessage = '비밀번호가 입력되지 않았습니다.'
          return
        }

        this.isProcessing = true

        axios.post('/fu/point-use/exchange-gift', {
          goodsCode: this.choiceItem.goodsCode,
          password: this.password,
        })
          .then(rs => {
            const { isGiftSuccess, userPoint } = rs.data

            changeUserPoint(userPoint)

            if (isGiftSuccess) {
              this.showAlertDialog('기프티콘이 전송되었습니다.', () => {
                this.isProcessing = false

                this.confirmClose()
              })
            } else {
              const failureMessage = '기프티콘 <b>발송이 지연되어 금일 내 전송</b> 될 예정입니다.<br/>(주말 및 공휴일 제외)'

              this.showAlertDialog(failureMessage, () => {
                this.isProcessing = false

                this.confirmClose()
              }, { isHtml: true })
            }
          })
          .catch(error => {
            this.isProcessing = false

            this.inValidMessage = errorFormatter(error, '상품 발송에 실패하였습니다. 잠시 후에 다시 시도해 주세요.')
          })
      }
    },

    fetchProducts() {
      axios.get('/fu/point-use/products')
        .then(rs => {
          this.products = rs.data
        })
        .catch(() => {
          this.showAlertDialog('상품 목록을 불러오는데 실패하였습니다.\n화면을 재 진입하여도 문제가 계속 될 경우, 관리자에 문의해 주세요.')
        })
    },
  },
}
</script>
