var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "contents" }, [
    _c("div", { staticClass: "point-wrap" }, [
      _c("div", { staticClass: "tab-nav common-type" }, [
        _c("ul", [
          _c("li", { class: { on: _vm.currTab === "point-use" } }, [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeTab("point-use")
                  },
                },
              },
              [_vm._v("포인트 사용하기")]
            ),
          ]),
          _c("li", { class: { on: _vm.currTab === "point-history" } }, [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeTab("point-history")
                  },
                },
              },
              [_vm._v("포인트 적립 내역")]
            ),
          ]),
          _c("li", { class: { on: _vm.currTab === "point-guide-rules" } }, [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeTab("point-guide-rules")
                  },
                },
              },
              [_vm._v("포인트 제도 안내")]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "tab-contents" },
        [_c(_vm.currTab, { tag: "component" })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }