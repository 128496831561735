<template>
  <div>
    <!-- 포인트 적립 내역 -->
    <div class="my-point-guide">
      <div class="use-point">
        <dl>
          <dt>사용 가능한 포인트</dt>
          <dd>
            <p class="num-txt">
              {{ (userPoint || 0) | $numberFormatter }}<span>P</span>
            </p>
          </dd>
        </dl>
      </div>
      <div class="use-point">
        <dl>
          <dt>소멸 예정 포인트</dt>
          <dd>
            <p class="d-day">
              <span>D-{{ expiringDays }}</span>
            </p>
            <p class="num-txt">
              {{ expiringPoint | $numberFormatter }}<span>P</span>
            </p>
          </dd>
        </dl>
      </div>
      <div class="month-mission">
        <span class="title">이달의 미션</span>
        <ol>
          <!-- <li><span>1.</span>약품 정보&nbsp;<strong>월 {{ drugReply.goal }}회 이상</strong> 댓글 작성 시 {{ drugReply.point | $numberFormatter }} P</li> -->
          <li v-if="webinarView !== null">
            <span>1.</span>제약 웹 세미나&nbsp;<strong>월 {{ webinarView.goal }}회 이상</strong> 일정 등록 시 {{ webinarView.point | $numberFormatter }} P
          </li>
          <li v-else>
            <span>1.</span>제약 웹 세미나&nbsp;<strong>등록 된 미션정보가 없습니다.</strong>
          </li>
          <li v-if="mediReply !== null">
            <span>2.</span>메디컬 트렌드&nbsp;<strong>월 {{ mediReply.goal }}회 이상</strong> 댓글 작성 시 {{ mediReply.point | $numberFormatter }} P
          </li>
          <li v-else>
            <span>2.</span>메디컬 트렌드&nbsp;<strong>등록 된 미션정보가 없습니다.</strong>
          </li>
          <li v-if="wChoice !== null">
            <span>3.</span>Weekly Choice&nbsp;<strong>월 {{ wChoice.goal }}회 이상</strong> 참여 시 {{ wChoice.point | $numberFormatter }} P
          </li>
          <li v-else>
            <span>3.</span>Weekly Choice&nbsp;<strong>등록 된 미션정보가 없습니다.</strong>
          </li>
        </ol>

        <div class="month-range-bar">
          <div class="participation-status">
            <!-- <div
              class="ps-item"
              :class="{ completion: drugReply.complete }"
              title="약품 정보"
            >
              <p class="title">
                약품 정보
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: drugReply.rate + '%' }"
                  />
                </div>
                <span
                  v-if="drugReply.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ drugReply.my }} <em>/</em> {{ drugReply.goal }}</span>
              </div>
            </div> -->
            <div
              v-if="webinarView !== null"
              class="ps-item"
              :class="{ completion: webinarView.complete }"
              title="제약 웹 세미나"
            >
              <p class="title">
                제약 웹 세미나
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: webinarView.rate + '%' }"
                  />
                </div>
                <span
                  v-if="webinarView.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ webinarView.my }} / {{ webinarView.goal }}</span>
              </div>
            </div>
            <div
              v-if="mediReply !== null"
              class="ps-item"
              :class="{ completion: mediReply.complete }"
              title="메디컬 트렌드"
            >
              <p class="title">
                메디컬 트렌드
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: mediReply.rate + '%' }"
                  />
                </div>
                <span
                  v-if="mediReply.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ mediReply.my }} <em>/</em> {{ mediReply.goal }}</span>
              </div>
            </div>
            <div
              v-if="wChoice !== null"
              class="ps-item"
              :class="{ completion: wChoice.complete }"
              title="Weekly Choice"
            >
              <p class="title">
                Weekly Choice
              </p>
              <div class="range-bar-wrap">
                <div class="range-bar">
                  <div
                    class="move-bar"
                    :style="{ width: wChoice.rate + '%' }"
                  />
                </div>
                <span
                  v-if="wChoice.complete"
                  class="range-state"
                >완료</span>
                <span
                  v-else
                  class="range-state"
                >{{ wChoice.my }} / {{ wChoice.goal }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="mission-tip">
      ※ 이달의 미션은 매월 변경됩니다.
    </p>

    <div class="date-wrap">
      <div class="radio-date">
        <input
          id="monthCheck1"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="1"
        >
        <label
          for="monthCheck1"
          style="cursor: pointer;"
        >1개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck2"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="3"
        >
        <label
          for="monthCheck2"
          style="cursor: pointer;"
        >3개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck3"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="6"
        >
        <label
          for="monthCheck3"
          style="cursor: pointer;"
        >6개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck4"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="12"
        >
        <label
          for="monthCheck4"
          style="cursor: pointer;"
        >1년</label>
      </div>

      <div
        class="period-calendar-wrap"
        style="display: flex; position: relative"
      >
        <div class="text-wrap calendar-type">
          <date-picker
            v-model="filter.startDate"
            :lang="datePicker.lang"
            value-type="format"
          />
        </div>
        <label class="bu-wave" />
        <div class="text-wrap calendar-type">
          <date-picker
            v-model="filter.endDate"
            :lang="datePicker.lang"
            value-type="format"
          />
        </div>
      </div>
    <!-- <button class="btn-period">
        검색
      </button> -->
    </div>
    <div class="tab-nav-in">
      <ul>
        <li :class="{ 'on': filter.plusMinus === null }">
          <a @click.prevent="filter.plusMinus = null">전체 내역</a>
        </li>
        <li :class="{ 'on': filter.plusMinus === 1 }">
          <a @click.prevent="filter.plusMinus = 1">적립 내역</a>
        </li>
        <li :class="{ 'on': filter.plusMinus === 2 }">
          <a @click.prevent="filter.plusMinus = 2">사용 내역</a>
        </li>
      </ul>
    </div>
    <div class="tab-contents-in">
      <div>
        <!-- 전체 내역 -->
        <div class="tb-list-type">
          <table>
            <colgroup>
              <col style="width: 150px">
              <col style="width: 250px">
              <col style="width: auto">
              <col style="width: 220px">
            </colgroup>

            <thead>
              <tr>
                <th>상태</th>
                <th>포인트</th>
                <th>상세 내용</th>
                <th>적립/사용 일자</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="m in list"
                :key="m.id"
              >
                <td><span>{{ m.plusMinusName }}</span></td>
                <td><span :class="m.amount > 0 ? 'txt-blue' : 'txt-red'">{{ m.amount | $numberFormatter }} P</span></td>
                <td class="txt-left">
                  <p class="ellipsis-txt">
                    {{ m.description }}
                  </p>
                </td>
                <td>{{ m.pointDate | $dateFormatter }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { isUserLoggedIn, changeUserPoint } from '@/auth/utils'
import DatePicker from 'vue2-datepicker'
import '@/libs/vue2-datepicker/calendar-vue.css'
import { locale } from '@/libs/vue2-datepicker/lang/ko-kr'

export default {
  components: {
    DatePicker,
  },

  data() {
    return {
      list: null,
      expiringDays: 15,
      expiringPoint: 0,
      myGoals: [],
      monthGoals: null,
      filter: {
        startDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        plusMinus: null,
      },
      datePicker: {
        lang: locale(),
      },
      drugReply: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      mediReply: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      webinarView: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      wChoice: {
        my: 0,
        goal: 0,
        rate: 0,
        complete: false,
      },
      monthCheck: 1
    }
  },
  computed: {
    ...mapGetters({
      userPoint: 'account/getPoint',
    }),
  },
  watch: {
    filter: {
      deep: true,

      handler() {
        this.fetchHistories()
      },
    },
    monthCheck() {
      this.filter.startDate = dayjs().add(-this.monthCheck, 'month').format('YYYY-MM-DD')
      this.filter.endDate = dayjs().format('YYYY-MM-DD')
    },
  },
  async mounted() {
    const isLogin = await isUserLoggedIn()

    if (!isLogin) {
      this.$router.push({
        name: 'login-main',
        query: { redirect: 'app-point-mall-main' }
      }).catch(() => {})
      return
    }

    this.fetchMonthMission()
    this.fetchUserPoint()
    this.fetchHistories()
    this.fetchExpiringPoint()
  },
  methods: {
    fetchUserPoint() {
      axios.get('/fu/point-history/user-point')
        .then(rs => {
          changeUserPoint(rs.data)
        })
    },
    fetchExpiringPoint() {
      axios.get('/fu/point-history/expiring-point')
        .then(rs => {
          const { availAmount, expiringDays } = rs.data
          this.expiringPoint = availAmount || 0
          this.expiringDays = expiringDays || 15
        })
    },
    missionSetting(data, category) {
      const mission = data.find(x => x.category === category)
      if (mission == null) return null

      return {
        my: mission.count,
        goal: mission.goal,
        point: mission.point,
        rate: mission.complete ? 100 : (mission.count >= mission.goal ? 100 : Math.round((mission.count / mission.goal) * 100)),
        complete: mission.complete
      }
    },
    fetchMonthMission() {
      axios.get('/fu/point-history/mission')
        .then(rs => {
          // this.drugReply = this.missionSetting(rs.data, 12)
          this.mediReply = this.missionSetting(rs.data, 13)
          this.webinarView = this.missionSetting(rs.data, 14)
          this.wChoice = this.missionSetting(rs.data, 16)
        })
    },
    fetchHistories() {
      axios.post('/fu/point-history/list', this.filter)
        .then(rs => {
          this.list = rs.data
        })
        .catch(() => {
          this.showAlertDialog('적립/사용 내역을 불러오는데 실패하였습니다.\n화면을 재 진입하여도 문제가 계속 될 경우, 관리자에 문의해 주세요.')
        })
    },
  },
}
</script>
